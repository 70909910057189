.map {
  position: relative;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

.marker-icon {
  font-family: 'FontAwesome';
  color: green;
  font-size: 40px;
  text-shadow: white 0px 0px 11px, black 4px -3px 7px;
}

.routing .menu-toggle {
  position: absolute;
  left: -20px;
  top: -20px;
  display: inline-block;
  border-left: 20px solid transparent;
  border-right: 20px solid #00bcf2;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  transform: rotate(45deg);
  cursor: pointer;
}

.routing.routing--minimized {
  max-width: 55px;
  max-height: 55px;
  background: white;
}

.routing.routing--minimized .menu-toggle {
  left: 15px;
  top: 15px;
  border-left: 20px solid grey;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.control {
  border-radius: 10px;
  box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.4);
  z-index: 1;
  position: absolute;
  left: 20px;
  top: 20px;
  border: none;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 320px;
  max-height: 100vh;
  overflow: hidden;
  min-height: 50px;
}

#menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  background: white none repeat scroll 0% 0%;
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 10px;
}

.routing {
  display: flex;
  flex-direction: column;
}

.routing>div {
  margin: 10px;
}

.surfaces {
  margin-top: 0;
  display: flex;
  flex-direction: column;
}

.surface {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.route-meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.route-meta-heading {
  font-size: 1.5em;
}

.loading {
  margin: 10px;
  text-align: center;
  font-size: 1.1em;
  color: grey;
}